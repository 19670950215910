<template>
  <v-container class="app-page">
    <!-- <header class="app-header">
      <v-container>
        <v-row class="content">
          <v-col cols="12" class="content-top">
            <router-link :to="{ name: 'empresa_nueva' }" class="back-arrow">
              <img class="card-icon" src="@/assets/header/back-arrow.svg" />
            </router-link>
            <div class="title">
              <div class="title-name">
                <img class="title-icon" src="@/assets/header/gns-icon.svg" />
                <span>Nueva empresa</span>
              </div>
              <div class="page-subtitle-name">
                <span>Configuración Manual</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" col-sm="12" class="company">
            <span>Completa el siguiente formulario</span>
          </v-col>
        </v-row>
      </v-container>
    </header> -->

    <v-row>
      <v-col>
        <v-form ref="form" v-model="valid" lazy-validation id="agregar-empresa" class="text-center">
          <v-text-field
            v-model="direction"
            :counter="10"
            :rules="directionRules"
            label="Dirección de portal web de recibos"
            required
          ></v-text-field>

          <v-text-field v-model="codigo" :rules="codigoRules" label="Código de empresa" required></v-text-field>

          <v-text-field
            v-model="user"
            :counter="10"
            :rules="userRules"
            label="Nombre de usuario"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="primary"
            class="btn mt-10 text-lowercase"
            @click="validate"
          >agregar</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    direction: "",
    directionRules: [
      (v) => !!v || "El nombre es requerido",
      (v) =>
        (v && v.length <= 10) ||
        "La dirección debe tener al menos 10 caracteres",
    ],
    codigo: "",
    codigoRules: [
      (v) => !!v || "El codigo es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Debe insertar un codigo valido",
    ],
    user: "",
    userRules: [
      (v) => !!v || "El usuario es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Debe insertar un usuario valido",
    ],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
   mounted() {
    this.$store.commit("setNavigationDrawer", false)
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Nueva Empresa");
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
#agregar-empresa {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  color: rgba(117, 117, 117, 0.5);
}
</style>